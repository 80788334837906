import * as React from "react"
import { Flex, Image, Paragraph } from "theme-ui"

import "../layouts/layout.css"
import "../../fonts/styles.css"
import ErrorBoundary from "../ErrorBoundary"
import Logo from "../../assets/logo.svg"
import { Link } from "../v2/Link"

export const CheckoutLayout = ({ children }) => {
  return (
    <ErrorBoundary>
      <Flex
        sx={{
          justifyContent: "space-between",
          position: ["relative", "absolute"],
          top: [null, 6],
          left: [null, 6],
          padding: [4, 0],
          zIndex: [4, 1],
        }}
      >
        <a href="/" sx={{ display: "block" }}>
          <Image src={Logo} sx={{ display: "block" }} />
        </a>

        {/*
        <Paragraph
          sx={{
            display: [null, "none"],
            color: "grayscale.600",
            fontSize: "sm",
          }}
        >
          <Link to="https://example.com">Log in</Link> for express checkout
        </Paragraph>
        */}
      </Flex>

      {children}
    </ErrorBoundary>
  )
}
